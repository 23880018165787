export function setCookie(name, json, expirationDays = 31) {
  let cookieValue = '';
  let expire = '';

  // stringじゃない場合はエラー
  if (typeof json !== 'string') {
    throw new Error('json is not a string');
  }

  cookieValue = name + '=' + encodeURIComponent(json) + ';';

  //Specify the path to set the cookie
  cookieValue += 'path=/ ;';

  expire = new Date();
  expire.setTime(expire.getTime() + 1000 * 3600 * 24 * expirationDays);
  expire.toUTCString();
  cookieValue += 'expires=' + expire + ';';

  document.cookie = cookieValue;
}

export function getCookie(key) {
  let cookieValue = '';
  let cookieArray = [];
  let result = undefined;

  cookieValue = document.cookie;

  if (cookieValue) {
    cookieArray = cookieValue.split(';');

    cookieArray.forEach((data) => {
      data = data.split('=');
      const dataKey = data[0].trim();
      let dataValue = data[1];
      if (isEncoded(dataValue)) {
        dataValue = decodeURIComponent(dataValue);
      }

      if (dataKey === key) {
        try {
          result = JSON.parse(dataValue);
        } catch (_) {
          result = dataValue;
        }
      }
    });
  }

  return result;
}

function isEncoded(str) {
  try {
    return str !== decodeURIComponent(str);
  } catch (e) {
    return false;
  }
}
